.c_almost-header {
  font-size: $type-size-4;
  font-weight: bold;
}

.c_img_with_auto img {
  width: auto;
}

.notice--warning code, .notice--info code {
  background-color: inherit;
  font-size: $type-size-5 !important;
}

.notice--bigger {
  font-size: $type-size-5 !important;
}

// add TOC to sidebar
.section-nav {
  @extend .toc__menu;
}

// no gray background in the sidebar
.mailerlite__form form {
  background-color: #fff;
}

// wider post width
.page {
  @include breakpoint($large) {
    width: 100%;
  }

  @include breakpoint($x-large) {
    width: 100%;
  }
}

// related with full width
.page__related {
  @include breakpoint($large) {
    float: left;
    width: 100%;
  }

  @include breakpoint($x-large) {
    float: left;
    width: 100%;
  }
}

// breadcrumbs floating to the left
.breadcrumbs {
  ol {
    @include breakpoint($large) {
      float: unset;
    }
  }
}

// better picture in archives
.author__avatar {
  img {
    max-width: unset;
    border-radius: unset;
  }
}

// main-page
.archive__item a {
  position: unset;
}

.c_item-title {
  margin-top: 0 !important; 
}

.c_item-teaser-title {
  padding: 0.5em;
  background: rgba(22, 22, 22, 0.8);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
